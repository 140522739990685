import { Store } from "base/app/store";
import { Dispatch } from "react";
import { AnyAction, MiddlewareAPI } from "redux";

export enum WorkState {
  IDLE = "idle",
  WORK = "work",
  FAIL = "fail",
  DONE = "done"
}

export enum SortDir {
  asc = "asc",
  desc = "desc"
}

// https://packagist.org/packages/artprima/query-filter-bundle
type FilterTypeNullable = "is null" | "is not null";
type FilterTypeRange = "between" | "not between";

type FilterType =
  | "eq"
  | "not eq"
  | "like"
  | "not like"
  | "gt"
  | "gte"
  | "in"
  | "not in"
  | "lt"
  | "lte";

interface FilterSimple {
  type: FilterType;
  x: string | number;
}

interface FilterNullable {
  type: FilterTypeNullable;
}

interface FilterRange {
  type: FilterTypeRange;
  x: string | number;
  y: string | number;
}

export interface Filter {
  [key: string]: string | FilterSimple | FilterRange | FilterNullable;
}

export interface Query {
  page?: number;
  limit?: number;
  sortby?: String;
  sortdir?: SortDir;
  filter?: Filter;
}

type ApiPromise = Promise<Response>;

export interface TDispatch extends Dispatch<FSA> {
  (action: AnyAction): ApiPromise;
}

export type StoreAPI = MiddlewareAPI<TDispatch, Store>;

export interface FSA extends AnyAction {
  payload: (store: StoreAPI) => ApiPromise;
  meta?: any;
}
