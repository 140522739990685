import React from "react";
import { Subtract } from "utility-types";
import SubjectManager, { OutProps } from "./SubjectManager";

export interface SubjectProp {
  subject: OutProps;
}

export const withSubject = <P extends SubjectProp>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, SubjectProp>> => (props: Subtract<P, SubjectProp>) => (
  <SubjectManager>
    {(out) => <Component subject={out} {...(props as P)} />}
  </SubjectManager>
);
