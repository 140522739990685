import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Toast } from "../../app/types";
import { Store } from "../../app/store";
import { Toasts } from "../../app/modules";

interface StateProps {
  items: Toast[];
}

const mapStateToProps = (state: Store): StateProps => ({
  items: state.toasts.items
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  hide: (id: number) => dispatch(Toasts.hide(id))
});

interface DispatchProps {
  hide: (id: number) => void;
}

type Props = StateProps & DispatchProps;

const Toaster: React.FC<Props> = ({ items, hide }) => (
  <>
    {items.map(({ content, visible, id }) => (
      <Snackbar
        key={id}
        open={visible || false}
        message={content}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => hide(id)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    ))}
  </>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Toaster);
