import { Button } from "@material-ui/core";
import { Action } from "base/app/types";
import React from "react";
import Link from "../ui/Link";

interface Props {
  action: Action;
  item: any;
}

const ActionComponent: React.FC<Props> = ({
  action: { label, button, link, icon: Icon },
  item,
}) => {
  if (button) {
    return (
      <Button onClick={() => button(item)}>
        {label}
        {!!Icon && <Icon />}
      </Button>
    );
  }

  if (link) {
    return (
      <Link to={link(item)}>
        {label}
        {!!Icon && <Icon />}
      </Link>
    );
  }

  return null;
};

export default ActionComponent;
