import { client } from "base/api/Client";
import { Query, WorkState } from "base/api/types";
import { async } from "base/api/utils";
import { Store } from "base/app/store";
import { CollectionModuleState, Entity } from "base/app/types";
import { AnyAction } from "redux";
import { createSelector } from "reselect";

export interface Endpoint extends Entity {
  id: number;
  name: string;
  token: string;
  model: number;
  station: number;
  visible: boolean;
  enabled: boolean;
  rssi?: number;
  uptime?: number;
  updated?: string;
  boot?: string;
  sizeHeap?: number;
  freeHeap?: number;
}

export type State = CollectionModuleState<Endpoint>;

const GET = async("@@endpoint/get");
const PUT = async("@@endpoint/put");

export const get = (data?: Query) => ({
  type: GET,
  payload: () => client.get("endpoint", { data }),
});

export const put = (
  id: number,
  { name, enabled, visible }: Partial<Endpoint>
) => ({
  type: PUT,
  payload: () =>
    client.put(`endpoint/${id}`, { data: { name, enabled, visible } }),
});

export const update = (data: Partial<Endpoint>) => ({
  type: PUT.success,
  payload: { data },
});

const updateSuccess = (state: State, data: Partial<Endpoint>): State => {
  const index = state.items.findIndex(({ id }) => id === data.id);
  if (index === -1) {
    return state;
  }

  let next = [...state.items];
  next[index] = { ...next[index], ...data };

  return {
    ...state,
    items: next,
    state: WorkState.DONE,
  };
};

const init: State = {
  items: [],
  total: 0,
  state: WorkState.IDLE,
};

export function reducer(
  state: State = init,
  { type, payload }: AnyAction
): State {
  switch (type) {
    case GET.start:
    case PUT.start:
      return {
        ...state,
        state: WorkState.WORK,
      };
    case GET.success:
      return {
        ...state,
        state: WorkState.DONE,
        items: payload.data,
        total: payload.meta.total_records,
      };
    case PUT.success:
      return updateSuccess(state, payload.data);
    case GET.error:
    case PUT.error:
      return {
        ...state,
        state: WorkState.FAIL,
        error: payload,
      };
    default:
      return state;
  }
}

export const findById = createSelector(
  ({ endpoint }: Store) => endpoint.items,
  (items) => (id: number) => items.find((item) => item.id === id)
);

export const findByToken = createSelector(
  ({ endpoint }: Store) => endpoint.items,
  (items) => (token: string) => items.find((item) => item.token === token)
);

export const findVisible = createSelector(
  ({ endpoint }: Store) => endpoint.items,
  (items) => items.filter(({ visible }) => !!visible)
);

export const findEnabled = createSelector(
  ({ endpoint }: Store) => endpoint.items,
  (items) => items.filter(({ enabled }) => !!enabled)
);
