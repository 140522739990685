import { TDispatch, WorkState } from "base/api/types";
import { Sessions, Toasts } from "base/app/modules";
import { Store } from "base/app/store";
import * as React from "react";
import { connect } from "react-redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";
import ActionButton from "../buttons/ActionButton";
import { TextField } from "./fields/TextField";

interface FormValues {
  username: string;
  password: string;
}

interface StateProps {
  username: string | null;
  status: WorkState;
}

const mapStateToProps = ({ sessions }: Store): StateProps => ({
  username: sessions.username,
  status: sessions.state,
});

interface DispatchProps {
  login: (data: FormValues) => void;
}

const mapDispatchToProps = (dispatch: TDispatch): DispatchProps => ({
  login: (data) =>
    dispatch(Sessions.login(data)).catch(() =>
      dispatch(Toasts.add(dispatch)({ content: "Invalid login or password" }))
    ),
});

type OwnProps = React.HTMLAttributes<HTMLFormElement>;

type Props = StateProps &
  DispatchProps &
  InjectedFormProps<FormValues, OwnProps> &
  OwnProps;

const LoginFormComponent: React.FC<Props> = ({
  handleSubmit,
  submitting,
  valid,
  username,
  login,
  status,
  ...rest
}) => (
  <Form
    {...rest}
    onSubmit={handleSubmit((data) => login({ username, ...data }))}
  >
    {!username && <TextField name="username" label="Username" />}

    <TextField name="password" type="password" label="Password" />

    <div>
      <ActionButton
        type="submit"
        disabled={submitting || !valid}
        status={status}
      >
        Sign in
      </ActionButton>
    </div>
  </Form>
);

const LoginFormReduxForm = reduxForm<{}, any>({ form: "login" })(
  LoginFormComponent
);

export const LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFormReduxForm);

const Form = styled.form`
  > div {
    margin: 5px 0 20px;
    width: 100%;

    &:last-child {
      text-align: center;
      margin-bottom: 0;
    }
  }
`;
