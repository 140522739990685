import { FSA, StoreAPI, TDispatch } from "base/api/types";
import { isFSA } from "base/api/utils";
import { modules, State } from "modules";
import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { SubmissionError } from "redux-form";
import thunk from "redux-thunk";
import { Form, Sessions, Toasts } from "./modules";

export interface Store extends State {
  form: Form.FormStateMap;
  toasts: Toasts.State;
  sessions: Sessions.State;
}

const rootReducer = combineReducers<Store>({
  form: Form.reducer,
  toasts: Toasts.reducer,
  sessions: Sessions.reducer,
  ...modules,
});

const asyncActionsMiddleware: Middleware<TDispatch, Store> = (
  store: StoreAPI
) => (next: TDispatch) => (action: FSA) => {
  if (isFSA(action)) {
    const { dispatch } = store;
    const { type, payload, meta } = action;
    dispatch({
      type: type.start,
      meta,
    });

    return payload(store)
      .then((resp) => {
        const { headers, status } = resp;
        const token = headers.get("Token");
        if (token) {
          dispatch(Sessions.setToken(token));
        }

        if (status >= 300 || status < 200) {
          throw resp;
        }

        const success = (payload: any) =>
          dispatch({
            type: type.success,
            payload,
          });

        // true propaganda of success...
        resp.clone().json().then(success).catch(success);

        return resp;
      })
      .catch((error) => {
        if (error?.status === 401) {
          dispatch(Sessions.unset());
        }

        dispatch({
          type: type.error,
          payload: error,
          meta,
        });

        throw meta?.reduxForm ? new SubmissionError(error) : error;
      });
  }

  return next(action);
};

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, asyncActionsMiddleware))
);
