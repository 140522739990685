import {
  AppBar,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { Filter } from "base/api/types";
import { Store } from "base/app/store";
import ActionButton from "base/component/buttons/ActionButton";
import DeviceLevel from "components/Device/DeviceLevel";
import EndpointsSwitch from "components/Endpoint/EndpointsSwitch";
import WSClient from "components/WSClient";
import { DeviceModule, EndpointModule } from "modules";
import { DeviceProp, withDevice } from "modules/device/withDevice";
import { Endpoint } from "modules/endpoint/endpointModule";
import { SubjectProp, withSubject } from "modules/subject/withSubject";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import { WSAction, WSHandlers, WSResource } from "types";
import EndpointStatsMemory from "components/Endpoint/EndpointStatsMemory";
import Timer from "components/Timer";

interface StateProps {
  endpointsEnabled: Endpoint[];
  endpointsVisible: Endpoint[];
  findByToken: (token: string) => Endpoint | undefined;
}

const mapStateToProps = (store: Store): StateProps => ({
  endpointsEnabled: EndpointModule.findEnabled(store),
  endpointsVisible: EndpointModule.findVisible(store),
  findByToken: EndpointModule.findByToken(store),
});

interface DispatchProps {
  fetchEndpoints: () => void;
  updateDevice: (device: Partial<DeviceModule.Device>) => void;
  updateEndpoint: (item: Partial<EndpointModule.Endpoint>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchEndpoints: () => dispatch(EndpointModule.get()),
  updateDevice: (device) => dispatch(DeviceModule.update(device)),
  updateEndpoint: (item) => dispatch(EndpointModule.update(item)),
});

type Props = StateProps & DispatchProps & DeviceProp & SubjectProp;

class DeviceChart extends React.Component<Props> {
  private setEndpoint = (endpoint: Endpoint, visible: boolean) =>
    this.props.updateEndpoint({ ...endpoint, visible });

  private fetchFiltered = () => {
    const filter: Filter = {
      "t.endpoint": {
        type: "in",
        x: this.props.endpointsVisible.map(({ id }) => id).join(","),
      },
    };

    return this.props.device.fetch(filter);
  };

  private update = () => {
    this.fetchFiltered();
    this.props.fetchEndpoints();
  };

  private getHandlers = () =>
    ({
      [WSResource.DEVICE]: ({ action, data }) => {
        if (action === WSAction.UPDATE) {
          if (data instanceof Array) {
            data.forEach(this.props.updateDevice);
          }
        }
      },
      [WSResource.BROADCAST]: ({ action, data, meta }) => {
        if (action === WSAction.CREATE) {
          const { findByToken, updateEndpoint } = this.props;
          const item = findByToken(meta);
          if (item) {
            updateEndpoint({ ...item, ...data });
          }
        }
      },
    } as WSHandlers);

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.endpointsVisible.length !== this.props.endpointsVisible.length
    ) {
      this.fetchFiltered();
    }
  };

  componentDidMount = this.props.fetchEndpoints;

  render = () => {
    const { device, endpointsEnabled, endpointsVisible } = this.props;

    return (
      <>
        <AppBar position="relative" color="default">
          <Grid container justify="center" alignItems="center" direction="row">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <EndpointsSwitch
                items={endpointsEnabled}
                selected={endpointsVisible}
                handleChange={this.setEndpoint}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <ActionButton
                onClick={this.update}
                icon={Icons.Refresh}
                status={device.state}
              />
            </Grid>
          </Grid>
        </AppBar>
        <Container>
          {endpointsVisible.map(
            ({ id, name, rssi, updated, uptime, boot, sizeHeap, freeHeap }) => (
              <Card key={id}>
                <CardHeader title={name} />
                <CardContent>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    <Item item xs={6}>
                      {!!rssi && (
                        <Grid container alignItems="center">
                          {rssi > -40 ? (
                            <Icons.SignalWifi3Bar />
                          ) : rssi > -65 ? (
                            <Icons.SignalWifi2Bar />
                          ) : rssi > -90 ? (
                            <Icons.SignalWifi1Bar />
                          ) : (
                            <Icons.SignalWifi0Bar />
                          )}
                          <span> </span>
                          <span>{rssi}dBm</span>
                        </Grid>
                      )}

                      {!!freeHeap && !!sizeHeap && (
                        <Grid container alignItems="center">
                          <Icons.Memory />
                          <span> </span>
                          <EndpointStatsMemory
                            size={sizeHeap}
                            free={freeHeap}
                          />
                        </Grid>
                      )}
                    </Item>

                    <Item item xs={6}>
                      {!!boot && (
                        <div>
                          <em>boot: </em>
                          <Timer time={boot} />
                        </div>
                      )}

                      {!!updated && (
                        <div>
                          <em>last: </em>
                          <Timer time={updated} />
                        </div>
                      )}
                    </Item>
                  </Grid>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    direction="row"
                  >
                    {device.items
                      .filter((item) => item.endpoint === id && !!item.visible)
                      .map((item) => (
                        <Item key={item.id} item sm={2} xs={4}>
                          <DeviceLevel item={item} />
                        </Item>
                      ))}
                  </Grid>
                </CardContent>
              </Card>
            )
          )}
        </Container>
        {!!endpointsEnabled.length && (
          <WSClient
            endpoints={endpointsEnabled}
            handlers={this.getHandlers()}
          />
        )}
      </>
    );
  };
}

const Item = styled(Grid)`
  padding: 20px;

  .marker_alarm {
    display: none;
  }

  &:hover .marker_alarm {
    display: block;
  }
`;

export default withSubject(
  withDevice(connect(mapStateToProps, mapDispatchToProps)(DeviceChart))
);
