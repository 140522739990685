import { AppBar, Grid } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { Filter, WorkState } from "base/api/types";
import { Store } from "base/app/store";
import { DateRange } from "base/app/types";
import ActionButton from "base/component/buttons/ActionButton";
import DateRangeField from "base/component/forms/fields/DateRangeField";
import EndpointsSwitch from "components/Endpoint/EndpointsSwitch";
import { EndpointModule, ReadingModule } from "modules";
import { DeviceProp, withDevice } from "modules/device/withDevice";
import { Endpoint } from "modules/endpoint/endpointModule";
import { getChartDatasets } from "modules/readingModule";
import { SubjectProp, withSubject } from "modules/subject/withSubject";
import moment from "moment";
import React from "react";
import { Line } from "react-chartjs-2";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { ChartDataset } from "types";

const chartFormat = "DD/MM/YYYY HH:mm";

interface Filters {
  created: DateRange;
}

interface State {
  filters: Filters;
  dateTilt: number;
}

interface StateProps {
  endpointsStatus: WorkState;
  endpointsEnabled: Endpoint[];
  endpointsVisible: Endpoint[];
  status: WorkState;
  getDatasets: () => ChartDataset[];
}

const mapStateToProps = (store: Store): StateProps => ({
  endpointsStatus: store.endpoint.state,
  endpointsEnabled: EndpointModule.findEnabled(store),
  endpointsVisible: EndpointModule.findVisible(store),
  status: store.reading.state,
  getDatasets: () => getChartDatasets(store),
});

interface DispatchProps {
  fetchEndpoints: () => void;
  fetchReadings: (filter: Filter) => void;
  updateEndpoint: (item: Partial<EndpointModule.Endpoint>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchEndpoints: () => dispatch(EndpointModule.get()),
  fetchReadings: (filter) => dispatch(ReadingModule.cget({ filter })),
  updateEndpoint: (item) => dispatch(EndpointModule.update(item)),
});

type Props = StateProps & DispatchProps & DeviceProp & SubjectProp;

const now = new Date();
const format = (date: Date) => moment(date).format("YYYY-MM-DDTHH:mm:ssZZ");
const tilt = (date: Date, tilt: number) =>
  moment(date).add(tilt, "hours").toDate();

class ReadingChart extends React.Component<Props, State> {
  state: State = {
    dateTilt: 0,
    filters: {
      created: {
        x: moment(now).subtract(1, "days").toDate(),
        y: now,
      },
    },
  };

  private fetchFiltered = () => {
    const { endpointsVisible } = this.props;
    const {
      filters: {
        created: { x, y },
      },
      dateTilt,
    } = this.state;

    let filter: Filter = {
      "t.created": {
        type: "between",
        x: format(tilt(x, dateTilt)),
        y: format(tilt(y, dateTilt)),
      },
      "d.visible": {
        type: "eq",
        x: 1,
      },
    };

    if (endpointsVisible.length) {
      filter["d.endpoint"] = {
        type: "in",
        x: endpointsVisible.map(({ id }) => id).join(","),
      };
    }

    return this.props.fetchReadings(filter);
  };

  private setFilters = (filters: Filters) =>
    this.setState({ filters, dateTilt: 0 });

  private setCreated = (created: DateRange) =>
    this.setFilters({ ...this.state.filters, created });

  private setCreatedFrom = (x: Date) =>
    this.setCreated({ ...this.state.filters.created, x });

  private setCreatedTill = (y: Date) =>
    this.setCreated({ ...this.state.filters.created, y });

  private update = () => this.setCreatedTill(new Date());

  private setEndpoint = (endpoint: Endpoint, visible: boolean) =>
    this.props.updateEndpoint({ ...endpoint, visible });

  private setDateTilt = (dateTilt: number) => {
    this.setState({
      dateTilt,
    });
  };

  componentDidMount = () => {
    const { fetchEndpoints, endpointsStatus, status } = this.props;
    if (endpointsStatus === WorkState.IDLE) {
      fetchEndpoints();
    }

    if (status !== WorkState.WORK) {
      this.fetchFiltered();
    }
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const { filters, dateTilt } = this.state;
    if (
      filters !== prevState.filters ||
      dateTilt !== prevState.dateTilt ||
      prevProps.endpointsVisible.length !== this.props.endpointsVisible.length
    ) {
      this.fetchFiltered();
    }
  };

  render = () => {
    const {
      getDatasets,
      endpointsEnabled,
      endpointsVisible,
      status,
    } = this.props;
    const { filters, dateTilt } = this.state;
    const options = {
      scales: {
        xAxes: [
          {
            type: "time",
            time: {
              parser: chartFormat,
              tooltipFormat: chartFormat,
              displayFormats: {
                year: "YYYY/MM/DD",
                quarter: "YYYY/MM/DD",
                month: "YYYY/MM/DD",
                week: "MM/DD HH",
                day: "MM/DD HH:mm",
                hour: "DD HH:mm",
                minute: "HH:mm",
                second: "HH:mm:ss",
              },
            },
          },
        ],
      },
    };

    return (
      <>
        <AppBar position="relative" color="default">
          <Grid container justify="center" alignItems="center" direction="row">
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <EndpointsSwitch
                items={endpointsEnabled}
                selected={endpointsVisible}
                handleChange={this.setEndpoint}
              />
            </Grid>
            <Grid item lg={2} md={4} sm={6} xs={12}>
              <DateRangeField
                label="Created"
                selected={{
                  x: tilt(filters.created.x, dateTilt),
                  y: tilt(filters.created.y, dateTilt),
                }}
                handleChangeFrom={this.setCreatedFrom}
                handleChangeTill={this.setCreatedTill}
                minDate={new Date("2019/10/13")}
                maxDate={now}
              />
              <br />

              <ActionButton
                onClick={this.update}
                icon={Icons.Refresh}
                status={status}
              />

              <ActionButton
                onClick={() => this.setDateTilt(dateTilt - 12)}
                icon={Icons.ArrowLeft}
              />

              <ActionButton
                onClick={() => this.setDateTilt(dateTilt + 12)}
                icon={Icons.ArrowRight}
              />
            </Grid>
          </Grid>
        </AppBar>
        <Line
          data={{
            datasets: getDatasets(),
          }}
          options={options}
        />
      </>
    );
  };
}

export default withSubject(
  withDevice(connect(mapStateToProps, mapDispatchToProps)(ReadingChart))
);
