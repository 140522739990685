import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "base/App";
import { store } from "./base/app/store";
import colors from "assets/styles/colors.module.scss";
import "base/index.scss";
import * as serviceWorker from "base/serviceWorker";

const { color03, color04, color06, color02 } = colors;

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      contrastText: color02,
      light: color04,
      main: color03,
      dark: color06
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
