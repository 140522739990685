import { Button, FormControlLabel } from "@material-ui/core";
import { Store } from "base/app/store";
import { TextField } from "base/component/forms/fields";
import { Device, find, put } from "modules/device/deviceModule";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";

const Form = styled.form`
  > div {
    margin: 5px 0 20px;
    width: 100%;

    &:last-child {
      text-align: center;
      margin-bottom: 0;
    }
  }
`;

interface StateProps {
  initialValues: Device | undefined;
}

const mapStateToProps = (state: Store, { iid }: OwnProps): StateProps => ({
  initialValues: find(state)(iid),
});

interface DispatchProps {
  put: (data: Partial<Device>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  put: ({ id, min, max, alertMax, alertMin, enabled, visible }) =>
    dispatch(put({ id, min, max, alertMax, alertMin, enabled, visible })),
});

interface OwnProps extends React.HTMLAttributes<HTMLFormElement> {
  iid: number;
}

type Props = StateProps & DispatchProps & OwnProps;

type FormProps = Props & InjectedFormProps<Device, Props>;

const DeviceForm: React.FC<FormProps> = ({
  handleSubmit,
  put,
  submitting,
  valid,
  ...rest
}) => (
  <Form {...rest} onSubmit={handleSubmit(put)}>
    <TextField name="min" label="min" />

    <TextField name="max" label="max" />

    <TextField name="alertMin" label="Alert (min)" />

    <TextField name="alertMax" label="Alert (max)" />

    <FormControlLabel
      label="enabled"
      control={<Field name="enabled" component="input" type="checkbox" />}
    />

    <FormControlLabel
      label="visible"
      control={<Field name="visible" component="input" type="checkbox" />}
    />

    <div>
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        disabled={submitting || !valid}
      >
        Submit
      </Button>
    </div>
  </Form>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<Device, Props>({ form: "device" })(DeviceForm)
);
