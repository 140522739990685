import {
  Link as MaterialLink,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { TableCellProps } from "@material-ui/core/TableCell";
import * as Icons from "@material-ui/icons";
import { Store } from "base/app/store";
import {
  Actions,
  CollectionModuleState,
  Entity,
  Structure,
} from "base/app/types";
import { Query, SortDir } from "base/api/types";
import React from "react";
import { connect } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import ActionComponent from "./ActionComponent";
import PaginationComponent from "./Pagination";

interface ValueCellProps<T extends Entity> extends TableCellProps {
  item: T;
  value: any;
  field: any;
}

const BooleanTransformer: React.FC<{ value: boolean }> = ({ value }) =>
  value ? <Icons.CheckCircleOutline /> : <Icons.HighlightOff />;

const ValueCell: React.FC<ValueCellProps<any>> = ({
  item,
  value,
  field,
  ...rest
}) => {
  const val = typeof value === "function" ? value(item) : item[field];

  return (
    <TableCell {...rest}>
      {typeof val === "boolean" ? <BooleanTransformer value={val} /> : val}
    </TableCell>
  );
};

interface State {
  limit: number;
  page: number;
  sortby?: String;
  sortdir?: SortDir;
}

interface OwnProps {
  fetchAction: (args: Query) => AnyAction;
  storeKey: keyof Store;
  structures: Structure[];
  actions?: Actions;
}

interface StateProps {
  moduleState: CollectionModuleState<any>;
}

const mapStateToProps = (store: Store, { storeKey }: OwnProps): StateProps => ({
  moduleState: store[storeKey] as CollectionModuleState<any>,
});

interface DispatchProps {
  fetch: (args: Query) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { fetchAction }: OwnProps
): DispatchProps => ({
  fetch: (args) => dispatch(fetchAction(args)),
});

type Props = StateProps & DispatchProps & OwnProps;

class Component extends React.Component<Props, State> {
  state: State = {
    limit: 20,
    page: 1,
  };

  private fetch() {
    const { limit, page, sortby, sortdir } = this.state;
    let params = { limit, page };
    if (sortby) {
      Object.assign(params, { sortby });
    }

    if (sortdir) {
      Object.assign(params, { sortdir });
    }

    this.props.fetch(params);
  }

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(_props: {}, state: State) {
    const { page, limit, sortby, sortdir } = this.state;
    if (
      state.page !== page ||
      state.limit !== limit ||
      state.sortby !== sortby ||
      state.sortdir !== sortdir
    ) {
      this.fetch();
    }
  }

  private setPage = (page: number) => this.setState({ page });

  private setLimit = (limit: number) => this.setState({ limit });

  private setOrder = (field: string) => {
    const { sortdir, sortby } = this.state;

    this.setState({
      sortdir:
        sortdir === SortDir.asc || field !== sortby
          ? SortDir.desc
          : SortDir.asc,
      sortby: field,
    });
  };

  public render() {
    const {
      structures,
      moduleState: { items, total },
      actions,
    } = this.props;
    const { page, limit, sortby, sortdir } = this.state;
    const hasPagin = total / limit > 1;
    const colSpan = structures.length;

    return (
      <Table>
        <TableHead>
          <TableRow>
            {structures.map(({ label, order, field }, index) => (
              <TableCell key={label || index}>
                {order ? (
                  <MaterialLink
                    onClick={() =>
                      this.setOrder(order === true ? field : order)
                    }
                  >
                    {sortby === order &&
                      (sortdir === SortDir.asc ? (
                        <Icons.ArrowDropDown />
                      ) : (
                        <Icons.ArrowDropUp />
                      ))}
                    <span>{label}</span>
                  </MaterialLink>
                ) : (
                  <span>{label}</span>
                )}
              </TableCell>
            ))}
            {actions && <TableCell>actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              {structures.map(({ value, label, field }, index) => (
                <ValueCell
                  key={label || index}
                  value={value}
                  field={field}
                  item={item}
                />
              ))}
              {actions && (
                <TableCell>
                  {Object.keys(actions).map((key, index) => (
                    <ActionComponent
                      action={actions[key]}
                      item={item}
                      key={index}
                    />
                  ))}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
        {hasPagin && (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 60, 100]}
                colSpan={colSpan}
                count={total}
                rowsPerPage={limit}
                page={page}
                onChangePage={(_e, page) => this.setPage(page)}
                onChangeRowsPerPage={(e) =>
                  this.setLimit(parseInt(e.target.value))
                }
                ActionsComponent={PaginationComponent}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
