import React from "react";
import styled from "styled-components";
import { DivProps } from "types";

interface Props extends DivProps {
  value: number;
  showLabel?: boolean;
}

const Marker: React.FC<Props> = ({ value, showLabel = true, ...rest }) => (
  <div {...rest}>{showLabel && <label>{(value * 100) | 0}%</label>}</div>
);

export default styled(Marker)`
  font-size: 10px;
  position: absolute;
  top: ${({ value }) => (1 - value) * 100}%;
  border-top: 1px solid red;
  width: 100%;
  transition: all 5s ease;

  label {
    position: absolute;
    transform: translate(-120%, -50%);
  }
`;
