import { getEnv, isDebug } from "base/api/utils";
import { EndpointModule } from "modules";
import * as React from "react";
import { WSAction, WSHandlers, WSMessage, WSResource } from "types";
import moment from "moment";

interface Props {
  handlers: Partial<WSHandlers>;
  endpoints: EndpointModule.Endpoint[];
}

class WSClient extends React.Component<Props> {
  private ws?: WebSocket;

  private close = () => {
    if (this.ws) {
      this.ws.close();
      this.ws = undefined;
    }
  };

  private getBaseUri = (baseUri: string): string => {
    const parts = baseUri.split("|");
    const partsCount = parts.length;
    if (partsCount < 2) {
      return baseUri;
    }

    const now = moment();
    const daysCount = now.daysInMonth();
    const chunkSize = Math.ceil(daysCount / partsCount);
    const day = parseInt(now.format("D"));
    for (let i = 0; i <= partsCount; i++) {
      if ((i + 1) * chunkSize >= day) {
        return parts[i];
      }
    }

    return baseUri;
  };

  private open = () => {
    const url = this.getBaseUri(getEnv("WSS_URL") || "");
    const state = this.ws?.readyState;
    if (!url || (state && [0, 1].includes(state))) {
      return undefined;
    }

    const ws = new WebSocket(url);
    if (isDebug) {
      // tslint:disable-next-line: no-console
      ws.onerror = console.error;
    }

    const { handlers, endpoints } = this.props;

    ws.onopen = () => {
      const data = {
        resource: WSResource.AUTH,
        action: WSAction.CREATE,
        data: endpoints.map(({ token }) => token),
      };
      if (isDebug) {
        // tslint:disable-next-line: no-console
        console.info(data);
      }

      ws.send(JSON.stringify(data));
    };

    ws.onmessage = ({ data }: MessageEvent) => {
      const msg: WSMessage = JSON.parse(data);
      if (isDebug) {
        // tslint:disable-next-line: no-console
        console.info(msg);
      }
      const handler = handlers[msg.resource];
      if (handler) {
        handler(msg);
      }
    };

    ws.onclose = (ev: CloseEvent) => {
      console.log(ev);
      if (isDebug) {
        // tslint:disable-next-line: no-console
        console.warn(ev);
      }

      // 1001 - reload, 1005 - client, 1006 - server
      if (ev.code === 1006) {
        this.open();
      }
    };

    this.ws = ws;
  };

  componentDidMount = this.open;
  componentDidUpdate = this.open;
  componentWillUnmount = this.close;

  render = () => null;
}

export default WSClient;
