import { AnyAction, Dispatch } from "redux";
import { Toast } from "../types";

const ADD = "TOASTS_ADD";
const HIDE = "TOASTS_HIDE";

let id = 1;

export const add = (dispatch: Dispatch) => (payload: Partial<Toast>) => {
  id++;
  payload.id = id;
  if (payload.visible === undefined) {
    payload.visible = true;
  }

  setTimeout(() => dispatch(hide(id)), payload.lifespan || 6000);

  return {
    type: ADD,
    payload
  };
};

export const hide = (payload: number) => ({
  type: HIDE,
  payload
});

export interface State {
  items: Toast[];
}

const init: State = {
  items: []
};

export function reducer(
  state: State = init,
  { type, payload }: AnyAction
): State {
  switch (type) {
    case ADD:
      return {
        ...state,
        items: [...state.items, payload]
      };
    case HIDE:
      const item = state.items.find(({ id }) => id === payload);
      if (!item) {
        return state;
      }
      item.visible = false;

      return {
        ...state,
        items: [...state.items]
      };
    default:
      return state;
  }
}
