import * as Icons from "@material-ui/icons";
import { Range } from "base/app/types";
import { fraction } from "base/utils";
import Label from "components/Label";
import Marker from "components/Marker";
import { DeviceModule } from "modules";
import { SubjectProp, withSubject } from "modules/subject/withSubject";
import React from "react";
import styled from "styled-components";
import { DivProps } from "types";

const Wrapper = styled.div`
  border: 1px solid #999;
  position: relative;
  width: 100%;
  height: 200px;

  > svg {
    @keyframes blinking {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: blinking 2s infinite;
  }
`;

const Value = styled.div<{ value: number; color: string }>`
  position: absolute;
  width: 100%;
  height: ${({ value }) => value * 100}%;
  background: ${({ color }) => color};
  bottom: 0;
  transition: all 5s ease;
`;

const getColor = (val: number, range: Range<number>) => {
  const pt = fraction(val, range);

  return `rgb(${(1 - pt) * 255}, ${pt * 255}, ${(pt - 1) * 255})`;
};
interface OwnProps extends DivProps {
  item: DeviceModule.Device;
}

type Props = SubjectProp & OwnProps;

const DeviceLevel: React.FC<Props> = ({ item, subject: { find }, ...rest }) => {
  const { value: val, min, max, alertMin, alertMax, subject } = item;
  const range = { x: min || 4095, y: max || 0 };
  const value = fraction(val, range);
  const minValue = fraction(alertMin || 4095, range);
  const maxValue = fraction(alertMax || 0, range);
  const hasAlert = val < (alertMax || 0) || val > (alertMin || 4095);

  return (
    <div {...rest}>
      <Wrapper>
        <Value value={value} color={getColor(val, range)} />
        <Marker
          value={minValue}
          showLabel={minValue < value - 0.05 || minValue > value + 0.05}
          className="marker_alarm"
        />
        <Marker value={value} />
        <Marker
          value={maxValue}
          showLabel={maxValue < value - 0.05 || maxValue > value + 0.05}
          className="marker_alarm"
        />
        {hasAlert && <Icons.Warning />}
      </Wrapper>
      <Label device={item} subject={find(subject)} />
    </div>
  );
};

export default withSubject(DeviceLevel);
