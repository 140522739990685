import * as Icons from "@material-ui/icons";
import { Actions, Structure } from "base/app/types";
import ReduxTable from "base/component/tables/ReduxTable";
import { SubjectModule } from "modules";
import SubjectForm from "./SubjectForm";
import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import Dialog from "base/component/dialogs/Dialog";
import styled from "styled-components";

type Item = SubjectModule.Subject;

const Color = styled.div<{ color: string }>`
  width: 20px;
  height: 20px;
  background: ${({ color }) => color};
`;

const structures: Structure[] = [
  {
    label: "name",
    field: "name",
    order: "t.name",
  },
  {
    label: "color",
    field: "color",
    order: "t.color",
    value: ({ color }: Item) => <Color color={color} />,
  },
];

const actions: Actions = {
  edit: {
    link: (item: Item) => `/subjects/${item.id}/edit`,
    icon: Icons.Edit,
  },
  delete: {
    link: (item: Item) => `/subjects/${item.id}/delete`,
    icon: Icons.Delete,
  },
};

const EditDialog: React.FC<RouteComponentProps<{ id: string }>> = ({
  history: { goBack },
  match: {
    params: { id },
  },
}) => (
  <Dialog title="Edit" abort={{ label: "Abort", handler: goBack }}>
    <SubjectForm iid={parseInt(id)} />
  </Dialog>
);

export default () => (
  <>
    <ReduxTable
      storeKey="subject"
      fetchAction={SubjectModule.get}
      structures={structures}
      actions={actions}
    />
    <Switch>
      <Route path="/subjects/:id/edit" component={EditDialog} />
    </Switch>
  </>
);
