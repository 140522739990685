import { Structure } from "base/app/types";
import ReduxTable from "base/component/tables/ReduxTable";
import { DateUtils } from "base/utils";
import { ReadingModule } from "modules";
import React from "react";
import { Reading } from "modules/readingModule";
import SubjectCell from "components/Subject/SubjectCell";

type Item = ReadingModule.Reading;

const format = (item: Item) => DateUtils.format(item.created);

const structures: Structure[] = [
  {
    label: "subject",
    field: "subject",
    order: "t.subject",
    value: ({ subject }: Reading) => <SubjectCell iid={subject} />,
  },
  {
    label: "value",
    field: "value",
    order: "t.value",
  },
  {
    label: "created",
    field: "created",
    order: "t.created",
    value: format,
  },
];

export default () => (
  <ReduxTable
    storeKey="reading"
    fetchAction={ReadingModule.cget}
    structures={structures}
  />
);
