import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { User } from "base/app/entity";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Store } from "../../app/store";
import { LoginForm } from "../forms/LoginForm";
import { RememberMePanel } from "../panels/RememberMePanel";

interface StateProps {
  username: string | null;
  user?: User;
}

type Props = StateProps;

const mapStateToProps = (state: Store): StateProps => {
  const { username, user } = state.sessions;

  return { username, user };
};

const LoginDialogComponent: React.FC<Props> = ({ username, user }) => {
  if (user) {
    return <Redirect to="/" />;
  }

  return (
    <Dialog open={true}>
      <DialogTitle>Sign in</DialogTitle>
      <DialogContent>
        {username && <RememberMePanel username={username} />}
        <LoginForm />
      </DialogContent>
    </Dialog>
  );
};

export const LoginDialog = connect(mapStateToProps)(LoginDialogComponent);
