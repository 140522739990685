import { SubjectProp, withSubject } from "modules/subject/withSubject";
import React from "react";
import { Link } from "react-router-dom";

interface OwnProps {
  iid: number;
}

type Props = SubjectProp & OwnProps;

const SubjectCell: React.FC<Props> = ({ subject, iid }) => {
  const item = subject.find(iid);
  if (!item) {
    return <>{iid}</>;
  }

  return <Link to={`/subjects/${iid}/edit`}>{item.name}</Link>;
};

export default withSubject(SubjectCell);
