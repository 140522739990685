import React from "react";

interface Props {
  size: number;
  free: number;
}

const kB = (raw: number) => Math.ceil(raw / 1024);
const percent = (val: number, size: number) => Math.ceil((val / size) * 100);

const EndpointStatsMemory: React.FC<Props> = ({ size, free }) => (
  <>
    <span title={free.toString()}>{kB(free)}/</span>
    <span title={size.toString()}>{kB(size)}kB </span>
    <span> ({percent(free, size)}%)</span>
  </>
);

export default EndpointStatsMemory;
