import { Store } from "base/app/store";
import { CollectionModuleState, Entity } from "base/app/types";
import { client } from "base/api/Client";
import { Query, WorkState } from "base/api/types";
import { async } from "base/api/utils";
import { AnyAction } from "redux";
import { createSelector } from "reselect";
import { Model } from "types";

export interface Device extends Entity {
  id: number;
  value: number;
  min?: number;
  max?: number;
  alertMin?: number;
  alertMax?: number;
  endpoint: number;
  subject: number;
  model: Model;
  visible: boolean;
  enabled: boolean;
}

export type State = CollectionModuleState<Device>;

const GET = async("@@device/fetch");
export const PUT = async("@@device/update");

export const get = (data?: Query) => ({
  type: GET,
  payload: () => client.get("device", { data }),
});

export const put = ({ id, ...data }: Partial<Device>) => ({
  type: PUT,
  payload: () => client.put(`device/${id}`, { data }),
});

export const update = (data: Partial<Device>) => ({
  type: PUT.success,
  payload: { data },
});

const updateSuccess = (state: State, data: Partial<Device>): State => {
  const index = state.items.findIndex(({ id }) => id === data.id);
  if (index === -1) {
    return state;
  }

  let next = [...state.items];
  next[index] = { ...next[index], ...data };

  return {
    ...state,
    items: next,
    state: WorkState.DONE,
  };
};

const init: State = {
  items: [],
  total: 0,
  state: WorkState.IDLE,
};

export function reducer(
  state: State = init,
  { type, payload }: AnyAction
): State {
  switch (type) {
    case GET.start:
    case PUT.start:
      return {
        ...state,
        state: WorkState.WORK,
      };
    case GET.success:
      return {
        ...state,
        state: WorkState.DONE,
        items: payload.data,
        total: payload.meta.total_records,
      };
    case PUT.success:
      return updateSuccess(state, payload.data);
    case GET.error:
    case PUT.error:
      return {
        ...state,
        state: WorkState.FAIL,
        error: payload,
      };
    default:
      return state;
  }
}

export const find = createSelector(
  (store: Store) => store.device.items,
  (items) => (id: number) => items.find((item) => item.id === id)
);
