import { FormControl, FormGroup, FormLabel } from "@material-ui/core";
import { Range } from "base/app/types";
import React from "react";
import DateField from "./DateField";

interface Props {
  label: string;
  selected: Range<Date>;
  handleChangeFrom: (date: Date) => void;
  handleChangeTill: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
}

const DateRangeField: React.FC<Props> = ({
  label,
  selected: { x, y },
  handleChangeFrom,
  handleChangeTill,
  minDate,
  maxDate,
  format
}) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">{label}</FormLabel>
    <FormGroup>
      <DateField
        selected={x}
        minDate={minDate}
        maxDate={y}
        handleChange={handleChangeFrom}
        format={format}
      />
      <DateField
        selected={y}
        minDate={x}
        maxDate={maxDate}
        handleChange={handleChangeTill}
        format={format}
      />
    </FormGroup>
  </FormControl>
);

export default DateRangeField;
