import React from "react";
import styled from "styled-components";
import { DeviceModule, SubjectModule } from "modules";
import moment from "moment";

interface Props extends React.HTMLAttributes<HTMLLabelElement> {
  device: DeviceModule.Device;
  subject?: SubjectModule.Subject;
}

const getTime = (time: string | number) => {
  if (typeof time === "string") {
    return moment(time);
  }

  return moment.unix(time);
};

const Label: React.FC<Props> = ({ device, subject, ...rest }) => (
  <label {...rest}>
    {subject?.name} <br />
    {device.updated ? getTime(device.updated).format("H:mm:ss") : "-"}
  </label>
);

export default styled(Label)`
  font-size: 12px;
`;
