import React, { useState, useEffect } from "react";
import moment from "moment";

interface Props {
  time: string | Date;
}

const format = (val: number) => (val < 10 ? "0" + val : val);
const getDuration = (till: moment.Moment, from: moment.Moment) =>
  moment.duration(till.diff(from));

const Timer: React.FC<Props> = ({ time }) => {
  const value = moment(time);
  const [till, setTill] = useState(moment());
  const [from, setFrom] = useState(time);

  useEffect(() => {
    const interval = setInterval(() => {
      setTill(moment());
      setFrom(time);
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  const dur = getDuration(till, moment(from));
  const days = dur.days();
  const hours = dur.hours();
  const minutes = dur.minutes();
  const seconds = dur.seconds();

  return (
    <span title={value.format("YY-MM-DD HH:mm:ss")}>
      {!!days && <>{days}d </>}
      {!!hours && <>{hours}:</>}
      {format(minutes)}:{format(seconds)}
    </span>
  );
};

export default Timer;
