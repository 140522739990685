export type DivProps = React.HTMLAttributes<HTMLDivElement>;

export enum Model {
  fc28 = 1,
  hd38 = 2,
  hw390 = 3,
  dht11_module = 4,
  dht11_simple = 5,
  touch = 6,
}

export enum Unit {
  raw = 1,
  percent = 2,
  degree = 3,
}

export interface ChartDataset {
  key: string;
  label: string;
  data: any[];
  borderColor: string;
  backgroundColor?: string;
  hoverBackgroundColor?: string;
  hoverBorderColor?: string;
  borderWidth?: number;
}

export enum WSResource {
  DEVICE = "device",
  AUTH = "auth",
  BROADCAST = "broadcast",
}

export enum WSAction {
  UPDATE = "update",
  CREATE = "create",
}

export interface WSMessage {
  resource: WSResource;
  action: WSAction;
  data: any;
  meta?: any;
}

export type WSHandlers = { [key in WSResource]: (message: WSMessage) => void };
