import * as DeviceModule from "./device/deviceModule";
import * as EndpointModule from "./endpoint/endpointModule";
import * as ReadingModule from "./readingModule";
import * as SubjectModule from "./subject/subjectModule";

export { ReadingModule, DeviceModule, EndpointModule, SubjectModule };

export interface State {
  device: DeviceModule.State;
  subject: SubjectModule.State;
  endpoint: EndpointModule.State;
  reading: ReadingModule.State;
}

export const modules = {
  device: DeviceModule.reducer,
  subject: SubjectModule.reducer,
  endpoint: EndpointModule.reducer,
  reading: ReadingModule.reducer
};
