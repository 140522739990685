import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
} from "@material-ui/core";
import { Endpoint } from "modules/endpoint/endpointModule";
import React from "react";

interface Props {
  items: Endpoint[];
  selected: Endpoint[];
  handleChange: (id: Endpoint, checked: boolean) => void;
}

const EndpointsSwitch: React.FC<Props> = ({
  items,
  selected,
  handleChange,
}) => (
  <FormControl component="fieldset">
    <FormLabel component="legend">Endpoint</FormLabel>
    <FormGroup>
      {items.map((item) => (
        <FormControlLabel
          key={item.id}
          control={
            <Switch
              checked={selected.some(({ id }) => id === item.id)}
              onChange={(_e, checked) => handleChange(item, checked)}
              value={item}
            />
          }
          label={item.name}
        />
      ))}
    </FormGroup>
  </FormControl>
);

export default EndpointsSwitch;
