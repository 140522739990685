import * as Icons from "@material-ui/icons";
import { Actions, Structure } from "base/app/types";
import Dialog from "base/component/dialogs/Dialog";
import ReduxTable from "base/component/tables/ReduxTable";
import { DeviceModule } from "modules";
import { Device } from "modules/device/deviceModule";
import React from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import SubjectCell from "../Subject/SubjectCell";
import DeviceForm from "./DeviceForm";

type Item = DeviceModule.Device;

const structures: Structure[] = [
  {
    label: "subject",
    field: "subject",
    order: "t.subject",
    value: ({ subject }: Device) => <SubjectCell iid={subject} />,
  },
  {
    label: "value",
    field: "value",
    order: "t.value",
  },
  {
    label: "enabled",
    field: "enabled",
    order: "t.enabled",
  },
  {
    label: "visible",
    field: "visible",
    order: "t.visible",
  },
];

const actions: Actions = {
  edit: {
    link: (item: Item) => `/devices/${item.id}/edit`,
    icon: Icons.Edit,
  },
  delete: {
    link: (item: Item) => `/devices/${item.id}/delete`,
    icon: Icons.Delete,
  },
};

const EditDialog: React.FC<RouteComponentProps<{ id: string }>> = ({
  history: { goBack },
  match: {
    params: { id },
  },
}) => (
  <Dialog title="Edit" abort={{ label: "Abort", handler: goBack }}>
    <DeviceForm iid={parseInt(id)} />
  </Dialog>
);

export default () => (
  <>
    <ReduxTable
      storeKey="device"
      fetchAction={DeviceModule.get}
      structures={structures}
      actions={actions}
    />
    <Switch>
      <Route path="/devices/:id/edit" component={EditDialog} />
    </Switch>
  </>
);
