import { getEnv, toQueryString } from "./utils";

/**
 * @see https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
 */
enum Status {
  OK = 200, // OK
  CREATED = 201, // Created
  ACCEPTED = 202, // Accepted
  NO_CONTENT = 204, // No content
  BAD_REQUEST = 400, // Bad Request
  UNAUTHORIZED = 401 // UNAUTHORIZED
}

interface Options extends RequestInit {
  data?: Record<string, any> | FormData;
}

interface IAPIError {
  message: string;
}

interface IAPIErrors {
  [key: string]: string | number | IAPIErrors;
}

class Client {
  private baseURL?: string = getEnv("API_URL");

  public constructor(baseUrl?: string) {
    if (baseUrl) {
      this.baseURL = baseUrl;
    }
  }

  public get(uri: string, options?: Options) {
    return this.call(uri, {
      ...options,
      method: "GET"
    });
  }

  public post(uri: string, options?: Options) {
    return this.call(uri, {
      ...options,
      method: "POST"
    });
  }

  public put(uri: string, options?: Options) {
    return this.call(uri, {
      ...options,
      method: "PUT"
    });
  }

  public delete(uri: string, options?: Options) {
    return this.call(uri, {
      ...options,
      method: "DELETE"
    });
  }

  protected call(uri: string, options?: Options) {
    if (!options) {
      options = { headers: {}, method: "GET" };
    }

    options.headers = {
      ...options.headers,
      Accept: "application/json"
    };

    const token = localStorage.getItem("token");
    if (token) {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`
      };
    }

    const { data, ...rest } = options;
    const { method } = options;
    if (["GET", "DELETE"].indexOf(method as string) !== -1) {
      uri = this.buildUri(uri, data);
    }

    if (["POST", "PUT"].indexOf(method as string) !== -1) {
      if (data) {
        if (data instanceof FormData) {
          rest.body = data;
        } else {
          (rest.headers as any)["Content-Type"] = "application/json";
          rest.body = JSON.stringify(data);
        }
      }
    }

    return fetch(`${this.baseURL}/${uri}`, rest).then(resp => {
      const { status } = resp;
      if (status > 400 && status < 500) {
        throw resp;
      }

      if (status === Status.BAD_REQUEST) {
        this.handle400(resp.clone());
      }

      return resp;
    });
  }

  protected async handle400(resp: Response) {
    if (resp.status === Status.BAD_REQUEST) {
      const json = await resp.json();
      if (json.errors) {
        const fields = json.errors;
        const errors: IAPIErrors = {};
        Object.keys(fields).forEach(key => {
          errors[key] = fields[key]
            .map((error: IAPIError) => error.message)
            .join(", ");
        });

        throw errors;
      }
    }
  }

  protected buildUri = (endpoint: string, data?: {}): string => {
    if (data && Object.keys(data).length) {
      endpoint += `?${toQueryString(data)}`;
    }

    return endpoint;
  };
}

export const client = new Client();
