import { ModuleState } from "base/app//types";
import { User } from "base/app/entity";
import { client } from "base/api/Client";
import { FSA, WorkState } from "base/api/types";
import { async } from "base/api/utils";
import { AnyAction } from "redux";
import { Toasts } from ".";

export interface State extends ModuleState {
  token: string | null;
  username: string | null;
  user?: User;
}

const FETCH_ACTIONS = async("@@sessions/fetch");
const LOGIN_ACTIONS = async("@@sessions/login");
const LOGOUT_ACTIONS = async("@@sessions/logout");
const SET_TOKEN_ACTION = "@@sessions/set_token";
const SET_USER_ACTION = "@@sessions/set_user";
const SET_USERNAME_ACTION = "@@sessions/set_username";

export const fetch = () => ({
  type: FETCH_ACTIONS,
  payload: () => client.get("session"),
});

export const login = (data: { username: string; password: string }): FSA => ({
  type: LOGIN_ACTIONS,
  payload: ({ dispatch }) =>
    client.post("session", { data }).then((resp) => {
      resp
        .clone()
        .json()
        .then(({ data }) => {
          if (data?.username) {
            dispatch(
              Toasts.add(dispatch)({ content: `Welcome ${data.username}!` })
            );
          }
        });

      return resp;
    }),
});

export const logout = () => ({
  type: LOGOUT_ACTIONS,
  payload: () => client.delete("session"),
});

export const unset = () => ({
  type: LOGOUT_ACTIONS.success,
});

export const setToken = (payload: string | null) => {
  payload
    ? localStorage.setItem("token", payload)
    : localStorage.removeItem("token");

  return {
    type: SET_TOKEN_ACTION,
    payload,
  };
};

export const getToken = () => localStorage.getItem("token");

export const setUsername = (payload: string | null) => {
  payload
    ? localStorage.setItem("username", payload)
    : localStorage.removeItem("username");

  return {
    type: SET_USERNAME_ACTION,
    payload,
  };
};

export const setUser = (payload?: User) => ({
  type: SET_USER_ACTION,
  payload,
});

export const getUsername = () => localStorage.getItem("username");

const init: State = {
  token: getToken(),
  username: getUsername(),
  state: WorkState.IDLE,
};

const fetchSuccess = (state: State, { data }: any): State => {
  if (!data?.username) {
    return { ...state };
  }

  const { username } = data;
  setUsername(username);

  return {
    ...state,
    state: WorkState.DONE,
    user: data,
    username,
  };
};

const fetchError = (state: State, payload: any): State => {
  setToken(null);

  return {
    ...state,
    state: WorkState.FAIL,
    error: payload,
    token: null,
    user: undefined,
  };
};

const logoutSuccess = (state: State): State => {
  setToken(null);

  return {
    ...state,
    state: WorkState.DONE,
    user: undefined,
    token: null,
  };
};

export function reducer(
  state: State = init,
  { type, payload }: AnyAction
): State {
  switch (type) {
    case LOGIN_ACTIONS.start:
    case FETCH_ACTIONS.start:
    case LOGOUT_ACTIONS.start:
      return {
        ...state,
        state: WorkState.WORK,
      };
    case FETCH_ACTIONS.success:
    case LOGIN_ACTIONS.success:
      return fetchSuccess(state, payload);
    case LOGOUT_ACTIONS.success:
      return logoutSuccess(state);
    case LOGIN_ACTIONS.error:
    case FETCH_ACTIONS.error:
    case LOGOUT_ACTIONS.error:
      return fetchError(state, payload);
    case SET_USER_ACTION:
      return {
        ...state,
        user: payload,
      };
    case SET_USERNAME_ACTION:
      return {
        ...state,
        username: payload,
      };
    case SET_TOKEN_ACTION:
      return {
        ...state,
        token: payload,
      };
    default:
      return state;
  }
}
