import { Button } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Sessions } from "../../app/modules";

interface OwnProps extends React.HTMLAttributes<HTMLDivElement> {
  username: string;
}

interface DispatchProps {
  unset(): void;
}

type Props = OwnProps & DispatchProps;

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  unset: () => dispatch(Sessions.setUsername(null))
});

const RememberMePanelComponent: React.FC<Props> = ({
  username,
  unset,
  ...rest
}) => (
  <div {...rest}>
    <span>Welcome back {username}!</span>
    <Button variant="text" color="primary" type="button" onClick={unset}>
      Not {username}?
    </Button>
  </div>
);

export const RememberMePanel = connect(
  null,
  mapDispatchToProps
)(RememberMePanelComponent);
