import { ModuleState, Range } from "base/app/types";
import { WorkState } from "base/api/types";
import * as DateUtils from "./DateUtils";

const { FAIL, DONE } = WorkState;

export const isLoaded = (mod: ModuleState | ModuleState[]): boolean => {
  if (mod instanceof Array) {
    return (
      mod.find(({ state }) => [DONE, FAIL].indexOf(state) === -1) === undefined
    );
  }

  return mod.state === DONE || mod.state === FAIL;
};

export { DateUtils };

export const fraction = (raw: number, { x, y }: Range<number>) => {
  const ret = (raw - x) / (y - x);
  if (ret > 1) {
    return 1;
  }

  if (ret < 0) {
    return 0;
  }

  return ret;
};
