import React from "react";
import { Subtract } from "utility-types";
import DeviceManager, { OutProps } from "./DeviceManager";

export interface DeviceProp {
  device: OutProps;
}

export const withDevice = <P extends DeviceProp>(
  Component: React.ComponentType<P>
): React.FC<Subtract<P, DeviceProp>> => (props: Subtract<P, DeviceProp>) => (
  <DeviceManager>
    {(out) => <Component device={out} {...(props as P)} />}
  </DeviceManager>
);
