import { LoginDialog } from "base/component/dialogs/LoginDialog";
import Logout from "base/component/Logout";
import Toaster from "base/component/ui/Toaster";
import SessionSentry from "base/container/SessionSentry";
import Layout from "components/Layout";
import * as React from "react";
import { BrowserRouter, Route } from "react-router-dom";

const App: React.FC = () => (
  <SessionSentry>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Route path="/login" exact={true} component={LoginDialog} />
      <Route path="/logout" exact={true} component={Logout} />
      <Route path="/" component={Layout} />
    </BrowserRouter>
    <Toaster />
  </SessionSentry>
);

export default App;
