import { AnyAction } from "redux";
import { FSA } from "./types";

interface IActions {
  start: string;
  success: string;
  error: string;
}

export function getEnv(key: string): string | undefined {
  return process.env[`REACT_APP_${key}`];
}

export const isDebug = process.env.NODE_ENV !== "production";

export const toQueryString = (
  params: { [key: string]: any },
  parentKey?: string
): string =>
  Object.keys(params)
    .map(key => {
      const newKey = parentKey ? `${parentKey}[${key}]` : key;
      const value = params[key];
      if (typeof value === "object") {
        return toQueryString(value, newKey);
      }

      return `${newKey}=${encodeURIComponent(value)}`;
    })
    .join("&");

export const async = (action: string): IActions => ({
  start: action + ":start",
  success: action + ":success",
  error: action + ":error"
});

export const isFSA = (action: AnyAction): action is FSA => {
  const { type, payload } = action;

  return (
    typeof payload === "function" &&
    typeof type === "object" &&
    !!type.start &&
    !!type.success &&
    !!type.error
  );
};
