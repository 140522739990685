import { User } from "base/app/entity";
import { Sessions } from "base/app/modules";
import { Store } from "base/app/store";
import * as React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dispatch } from "redux";

interface StateProps {
  user?: User;
}

const mapStateToProps = (state: Store): StateProps => ({
  user: state.sessions.user
});

interface DispatchProps {
  logout: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  logout: () => dispatch(Sessions.logout())
});

type Props = StateProps & DispatchProps;

class Logout extends React.Component<Props> {
  componentDidMount() {
    const { user, logout } = this.props;
    if (user) {
      logout();
    }
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
