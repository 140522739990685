import {
  Button,
  Dialog as Base,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import React from "react";

interface Action {
  label: string;
  handler(): void;
}

interface Props {
  title: string;
  abort?: Action;
  confirm?: Action;
}

const Dialog: React.FC<Props> = ({ title, abort, confirm, children }) => {
  const onAbort = abort ? abort.handler : undefined;
  const showActions = abort || confirm;

  return (
    <Base
      open
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onEscapeKeyDown={onAbort}
      onBackdropClick={onAbort}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      {showActions && (
        <DialogActions>
          {abort && (
            <Button onClick={abort.handler} color="default">
              {abort.label}
            </Button>
          )}
          {confirm && (
            <Button onClick={confirm.handler} color="secondary" autoFocus>
              {confirm.label}
            </Button>
          )}
        </DialogActions>
      )}
    </Base>
  );
};

export default Dialog;
