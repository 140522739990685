import { TextField as Base, TextFieldProps } from "@material-ui/core";
import * as React from "react";
import { Field, WrappedFieldProps } from "redux-form";

type Props = TextFieldProps & WrappedFieldProps;

const BaseField: React.FC<Props> = ({
  input,
  meta: { touched, error },
  ...rest
}: Props) => (
  <Base
    {...input}
    {...rest}
    variant="outlined"
    error={touched && error}
    helperText={touched && error}
  />
);

export const TextField: React.FC<TextFieldProps> = ({ label, name, type }) => (
  <Field label={label} name={name} type={type} component={BaseField} />
);
