import * as React from "react";
import "./Loader.scss";

const Loader: React.FC = () => (
  <div className="LoaderWrapper">
    <div className="Loader" />
  </div>
);

export default Loader;
