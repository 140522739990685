// https://material-ui.com/guides/composition/#component-property
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from "react-router-dom";
import MaterialLink, {
  LinkProps as MaterialLinkProps
} from "@material-ui/core/Link";

type Props = RouterLinkProps & MaterialLinkProps;

const AdapterLink = React.forwardRef((props: any, ref: any) => (
  <RouterLink innerRef={ref} {...props} />
));

const Link: React.FC<Props> = ({ to, children, ...rest }) => (
  <MaterialLink component={AdapterLink} to={to} {...rest}>
    {children}
  </MaterialLink>
);

export default Link;
