import { Button } from "@material-ui/core";
import { Store } from "base/app/store";
import { TextField } from "base/component/forms/fields";
import { find, put, Subject } from "modules/subject/subjectModule";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";

const Form = styled.form`
  > div {
    margin: 5px 0 20px;
    width: 100%;

    &:last-child {
      text-align: center;
      margin-bottom: 0;
    }
  }
`;

interface StateProps {
  initialValues: Subject | undefined;
}

const mapStateToProps = (state: Store, { iid }: OwnProps): StateProps => ({
  initialValues: find(state)(iid),
});

interface DispatchProps {
  put: (data: Partial<Subject>) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  put: ({ id, name, color }) => dispatch(put({ id, name, color })),
});

interface OwnProps extends React.HTMLAttributes<HTMLFormElement> {
  iid: number;
}

type Props = StateProps & DispatchProps & OwnProps;

type FormProps = Props & InjectedFormProps<Subject, Props>;

const SubjectForm: React.FC<FormProps> = ({
  handleSubmit,
  submitting,
  valid,
  put,
  ...rest
}) => (
  <Form {...rest} onSubmit={handleSubmit(put)}>
    <TextField name="name" label="name" />

    <TextField name="color" label="color" type="color" />

    <div>
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        disabled={submitting || !valid}
      >
        Submit
      </Button>
    </div>
  </Form>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<Subject, Props>({ form: "subject" })(SubjectForm)
);
