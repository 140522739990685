import { debounce } from "lodash";
import React from "react";
import ReactDatePicker from "react-datepicker";

interface Props {
  selected: Date;
  handleChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
}

const DateField: React.FC<Props> = ({
  selected,
  handleChange,
  minDate,
  maxDate,
  format = "yyyy/MM/dd HH:mm"
}) => (
  <ReactDatePicker
    selected={selected}
    minDate={minDate}
    maxDate={maxDate}
    onChange={debounce(handleChange, 999)}
    dateFormat={format}
    showTimeInput
    withPortal
    shouldCloseOnSelect={false}
  />
);

export default DateField;
