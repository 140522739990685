import { Store } from "base/app/store";
import { Filter, WorkState } from "base/api/types";
import { SubjectModule } from "modules";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

interface StateProps {
  state: SubjectModule.State;
  find: (id: number) => SubjectModule.Subject | undefined;
}

const mapStateToProps = (store: Store): StateProps => ({
  state: store.subject,
  find: (id) => SubjectModule.find(store)(id),
});

interface DispatchProps {
  fetch: (filter?: Filter) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetch: (filter) =>
    dispatch(SubjectModule.get(filter ? { filter } : undefined)),
});

export type OutProps = StateProps & DispatchProps;

interface OwnProps {
  children(props: OutProps): JSX.Element;
}

type Props = OutProps & OwnProps;

class SubjectManager extends React.Component<Props> {
  componentDidMount() {
    const {
      state: { state, items },
      fetch,
    } = this.props;
    if (state === WorkState.IDLE && !items.length) {
      fetch();
    }
  }

  render = (): JSX.Element => {
    const { children, ...rest } = this.props;

    return children(rest);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectManager);
