import {
  Button,
  ButtonProps,
  IconProps,
  CircularProgress,
} from "@material-ui/core";
import { debounce } from "lodash";
import React from "react";
import { WorkState } from "base/api/types";

interface Props extends ButtonProps {
  wait?: number;
  icon?: React.ComponentType<IconProps>;
  status?: WorkState;
}

const ActionButton: React.FC<Props> = ({
  children,
  onClick,
  wait = 300,
  size = "large",
  variant = "contained",
  icon: Icon,
  status,
  disabled,
  ...rest
}) => {
  const busy = status && status === WorkState.WORK;
  
  return (
    <Button
      variant={variant}
      size={size}
      onClick={onClick ? debounce(onClick, wait) : undefined}
      disabled={disabled || busy}
      {...rest}
    >
      {busy ? (
        <CircularProgress />
      ) : (
        <>
          {children}
          {!!Icon && <Icon fontSize="large" />}
        </>
      )}
    </Button>
  );
};

export default ActionButton;
