import * as Icons from "@material-ui/icons";
import { Actions, Structure } from "base/app/types";
import ReduxTable from "base/component/tables/ReduxTable";
import { DateUtils } from "base/utils";
import { EndpointModule } from "modules";
import EndpointForm from "./EndpointForm";
import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import Dialog from "base/component/dialogs/Dialog";

type Item = EndpointModule.Endpoint;

const format = (item: Item) => DateUtils.format(item.created);

const structures: Structure[] = [
  {
    label: "name",
    field: "name",
    order: "t.name",
  },
  {
    label: "token",
    field: "token",
    order: "t.token",
  },
  {
    label: "enabled",
    field: "enabled",
    order: "t.enabled",
  },
  {
    label: "visible",
    field: "visible",
    order: "t.visible",
  },
  {
    label: "updated",
    field: "updated",
    order: "t.updated",
    value: format,
  },
];

const actions: Actions = {
  edit: {
    link: (item: Item) => `/endpoints/${item.id}/edit`,
    icon: Icons.Edit,
  },
  delete: {
    link: (item: Item) => `/endpoints/${item.id}/delete`,
    icon: Icons.Delete,
  },
};

const EditDialog: React.FC<RouteComponentProps<{ id: string }>> = ({
  history: { goBack },
  match: {
    params: { id },
  },
}) => (
  <Dialog title="Edit" abort={{ label: "Abort", handler: goBack }}>
    <EndpointForm iid={parseInt(id)} />
  </Dialog>
);

export default () => (
  <>
    <ReduxTable
      storeKey="endpoint"
      fetchAction={EndpointModule.get}
      structures={structures}
      actions={actions}
    />
    <Switch>
      <Route path="/endpoints/:id/edit" component={EditDialog} />
    </Switch>
  </>
);
