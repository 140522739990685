import { Store } from "base/app/store";
import { Filter, WorkState } from "base/api/types";
import { DeviceModule } from "modules";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

interface StateProps extends DeviceModule.State {
  find: (id: number) => DeviceModule.Device | undefined;
}

const mapStateToProps = (store: Store): StateProps => ({
  ...store.device,
  find: (id) => DeviceModule.find(store)(id),
});

interface DispatchProps {
  fetch: (filter?: Filter) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetch: (filter) =>
    dispatch(DeviceModule.get(filter ? { filter } : undefined)),
});

export type OutProps = StateProps & DispatchProps;

interface OwnProps {
  children(props: OutProps): JSX.Element;
}

type Props = OutProps & OwnProps;

class DeviceManager extends React.Component<Props> {
  componentDidMount() {
    const { state, items, fetch } = this.props;
    if (state === WorkState.IDLE && !items.length) {
      fetch();
    }
  }

  render = (): JSX.Element => {
    const { children, ...rest } = this.props;

    return children(rest);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManager);
