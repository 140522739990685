import { Store } from "base/app/store";
import { CollectionModuleState, Entity } from "base/app/types";
import { client } from "base/api/Client";
import { Query, WorkState } from "base/api/types";
import { async } from "base/api/utils";
import { fraction } from "base/utils";
import * as DeviceModule from "modules/device/deviceModule";
import * as SubjectModule from "modules/subject/subjectModule";
import moment from "moment";
import { AnyAction } from "redux";
import { createSelector } from "reselect";
import { ChartDataset, Unit } from "types";

export interface Reading extends Entity {
  id: number;
  device: number;
  unit: Unit;
  subject: number;
  value: string;
}

export type State = CollectionModuleState<Reading>;

const GET = async("@@reading/get");

export const cget = (data: Query) => ({
  type: GET,
  payload: () => client.get("reading", { data }),
});

const init: State = {
  items: [],
  total: 0,
  state: WorkState.IDLE,
};

export function reducer(
  state: State = init,
  { type, payload }: AnyAction
): State {
  switch (type) {
    case GET.start:
      return {
        ...state,
        state: WorkState.WORK,
      };
    case GET.success:
      return {
        ...state,
        state: WorkState.DONE,
        items: payload.data,
        total: payload.meta.total_records,
      };
    case GET.error:
      return {
        ...state,
        state: WorkState.FAIL,
        error: payload,
      };
    default:
      return state;
  }
}

export const getChartDatasets = createSelector(
  [
    (store: Store) => store.reading.items as Reading[],
    DeviceModule.find,
    SubjectModule.find,
  ],
  (items, findDevice, findSubject) =>
    items.reduce(
      (
        ret: ChartDataset[],
        { device: deviceId, subject: subjectId, unit, value, created }: Reading
      ) => {
        const device = findDevice(deviceId);
        const subject = findSubject(subjectId);
        if (!device || !subject) {
          return ret;
        }

        const key = `${deviceId}-${unit}-${subjectId}`;
        const val = parseFloat(value);
        const data = {
          x: moment(created),
          y:
            unit === Unit.raw
              ? (
                  fraction(val, {
                    x: device.min || 4095,
                    y: device.max || 0,
                  }) * 100
                ).toFixed(2)
              : val,
        };
        const found = ret.find((item) => item.key === key);
        if (found) {
          found.data.push(data);

          return ret;
        }

        ret.push({
          key,
          label: `${subject.name} (${deviceId})`,
          borderColor: subject.color,
          data: [data],
        });

        return ret;
      },
      []
    )
);
