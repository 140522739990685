import { Sessions } from "base/app/modules";
import { Store } from "base/app/store";
import Loader from "base/component/ui/Loader";
import { isLoaded } from "base/utils";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

interface StateProps {
  sessions: Sessions.State;
}

const mapStateToProps = ({ sessions }: Store): StateProps => ({
  sessions
});

interface DispatchProps {
  fetch: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetch: () => dispatch(Sessions.fetch())
});

type Props = StateProps & DispatchProps;

class SessionSentry extends React.Component<Props> {
  componentDidMount() {
    const { sessions, fetch } = this.props;
    const { token, user } = sessions;

    if (token && !user && !isLoaded(sessions)) {
      fetch();
    }
  }

  render() {
    const { sessions, children } = this.props;
    if (sessions.token && !isLoaded(sessions)) {
      return <Loader />;
    }

    return children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionSentry);
