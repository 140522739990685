import { Button, FormControlLabel } from "@material-ui/core";
import { Store } from "base/app/store";
import { TextField } from "base/component/forms/fields";
import { Endpoint, findById, put } from "modules/endpoint/endpointModule";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import styled from "styled-components";

const Form = styled.form`
  > div {
    margin: 5px 0 20px;
    width: 100%;

    &:last-child {
      text-align: center;
      margin-bottom: 0;
    }
  }
`;

interface StateProps {
  initialValues: Endpoint | undefined;
}

const mapStateToProps = (state: Store, { iid }: OwnProps): StateProps => ({
  initialValues: findById(state)(iid),
});

interface DispatchProps {
  put: (data: Endpoint) => void;
}

const mapDispatchToProps = (
  dispatch: Dispatch,
  { iid }: OwnProps
): DispatchProps => ({
  put: (data) => dispatch(put(iid, data)),
});

interface OwnProps extends React.HTMLAttributes<HTMLFormElement> {
  iid: number;
}

type Props = StateProps & DispatchProps & OwnProps;

type FormProps = Props & InjectedFormProps<Endpoint, Props>;

const EndpointForm: React.FC<FormProps> = ({
  submitting,
  valid,
  put,
  handleSubmit,
  ...rest
}) => (
  <Form {...rest} onSubmit={handleSubmit(put)}>
    <TextField name="name" label="name" />

    <FormControlLabel
      label="enabled"
      control={<Field name="enabled" component="input" type="checkbox" />}
    />

    <FormControlLabel
      label="visible"
      control={<Field name="visible" component="input" type="checkbox" />}
    />

    <div>
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        disabled={submitting || !valid}
      >
        Submit
      </Button>
    </div>
  </Form>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm<Endpoint, Props>({ form: "endpoint" })(EndpointForm)
);
