import { AppBar, Container, Paper, Tab, Tabs } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { Sessions } from "base/app/modules";
import { Store } from "base/app/store";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import DeviceChart from "./Device/DeviceChart";
import DeviceTable from "./Device/DeviceTable";
import EndpointTable from "./Endpoint/EndpointTable";
import ReadingChart from "./Reading/ReadingChart";
import ReadingTable from "./Reading/ReadingTable";
import SubjectTable from "./Subject/SubjectTable";

interface StateProps {
  sessions: Sessions.State;
}

const mapStateToProps = (state: Store): StateProps => ({
  sessions: state.sessions,
});

type OwnProps = React.HTMLAttributes<HTMLDivElement>;

type Props = StateProps & OwnProps & RouteComponentProps;

class Layout extends React.PureComponent<Props> {
  private getTab = () => {
    const parts = this.props.location.pathname.split("/");
    const part = "/" + parts[1];

    return [
      "/logout",
      "/dashbord",
      "/chart",
      "/endpoints",
      "/devices",
      "/subjects",
      "/readings",
    ].includes(part)
      ? part
      : false;
  };

  private handleChange = (_e: any, path: string) =>
    this.props.history.push(path);

  public render() {
    const {
      sessions: { user },
      location: { pathname },
    } = this.props;
    if (!user) {
      return <Redirect to="/login" />;
    }

    if (pathname === "/" || !pathname) {
      return <Redirect to="/dashbord" />;
    }

    return (
      <Container maxWidth={false}>
        <AppBar position="static" color="default">
          <Tabs
            onChange={this.handleChange}
            value={this.getTab()}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label="dashbord"
              value="/dashbord"
              icon={<Icons.Dashboard />}
            />
            <Tab label="chart" value="/chart" icon={<Icons.ShowChart />} />
            <Tab
              label="endpoints"
              value="/endpoints"
              icon={<Icons.DeveloperBoard />}
            />
            <Tab
              label="subjects"
              value="/subjects"
              icon={<Icons.LocalFlorist />}
            />
            <Tab
              label="devices"
              value="/devices"
              icon={<Icons.SettingsInputComponent />}
            />
            <Tab label="readings" value="/readings" icon={<Icons.Speed />} />
            <Tab
              label="logout"
              value="/logout"
              icon={<Icons.PowerSettingsNew />}
            />
          </Tabs>
        </AppBar>
        <Paper>
          <Switch>
            <Route path="/dashbord" component={DeviceChart} />
            <Route path="/chart" component={ReadingChart} />
            <Route path="/endpoints" component={EndpointTable} />
            <Route path="/devices" component={DeviceTable} />
            <Route path="/subjects" component={SubjectTable} />
            <Route path="/readings" component={ReadingTable} />
          </Switch>
        </Paper>
      </Container>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Layout));
